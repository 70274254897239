import { supabase } from './supabase.js';

let cachedSession = null;
let cachedSubscription = null;
let lastChecked = 0;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

export async function getAuthState() {
    const now = Date.now();
    if (cachedSession && now - lastChecked < CACHE_DURATION) {
        return { session: cachedSession, subscription: cachedSubscription };
    }

    try {
        const { data, error } = await supabase.auth.getSession();
        if (error) throw error;

        const session = data.session;

        if (!session) {
            clearAuthState();
            return { session: null, subscription: null };
        }

        const { data: refreshData, error: refreshError } = await supabase.auth.refreshSession();
        
        if (refreshError) {
            console.error('Error refreshing session:', refreshError);
            clearAuthState();
            return { session: null, subscription: null };
        }

        const refreshedSession = refreshData.session;

        cachedSession = refreshedSession;
        cachedSubscription = await checkUserSubscription(refreshedSession.user.id);
        lastChecked = now;

        return { session: cachedSession, subscription: cachedSubscription };
    } catch (error) {
        console.error('Error in getAuthState:', error);
        clearAuthState();
        return { session: null, subscription: null };
    }
}

export async function checkAuth() {
    try {
        const { session, subscription } = await getAuthState();

        if (!session) {
            console.log('No active session');
            return { session: null, subscription: null, shouldRedirect: false };
        }

        console.log('Active session found, checking subscription');
        console.log('User ID:', session.user.id);

        if (!subscription) {
            console.log('No active recurring subscription found, checking for all-day plan');
            const allDaySubscription = await checkAllDaySubscription(session.user.id);
            
            if (allDaySubscription) {
                console.log('Valid all-day subscription found');
                return { session, subscription: allDaySubscription, shouldRedirect: false };
            }

            console.log('No valid subscription found');
            const isOnNewUserPage = window.location.pathname === '/new-user';
            if (isOnNewUserPage) {
                console.log('User is on new-user page, allowing to proceed');
                return { session, subscription: null, shouldRedirect: false };
            } else {
                console.log('User is not on new-user page and has no active subscription');
                await signOut(); // Call signOut before returning
                return { session: null, subscription: null, shouldRedirect: true };
            }
        }

        console.log('Active subscription found:', JSON.stringify(subscription, null, 2));
        return { session, subscription, shouldRedirect: false };
    } catch (error) {
        console.error('Unexpected error in checkAuth:', error);
        console.error('Error stack:', error.stack);
        await signOut(); // Call signOut in case of error
        return { session: null, subscription: null, shouldRedirect: true };
    }
}

async function checkAllDaySubscription(userId) {
    try {
        console.log('Checking for all-day subscription for user:', userId);
        const { data, error } = await supabase
            .from('subscriptions')
            .select('subscription_id, user_id, price_id, start_date, end_date, status, is_current, plan_type')
            .eq('user_id', userId)
            .eq('plan_type', 'all-day')
            .eq('is_current', true)
            .order('created_at', { ascending: false })
            .limit(1);

        console.log('All-day subscription query result:', JSON.stringify({ data, error }, null, 2));

        if (error) {
            console.error('Error fetching all-day subscription:', error);
            return null;
        }

        if (data && data.length > 0) {
            const subscription = data[0];
            const now = new Date();
            const startDate = new Date(subscription.start_date);
            const endDate = new Date(subscription.end_date);

            console.log('All-day subscription found:', JSON.stringify(subscription, null, 2));
            console.log('Current time:', now.toISOString());
            console.log('Start date:', startDate.toISOString());
            console.log('End date:', endDate.toISOString());

            if (now >= startDate && now <= endDate && subscription.status === 'active') {
                console.log('Valid all-day subscription confirmed');
                return subscription;
            } else {
                console.log('All-day subscription found but not currently active');
                if (now < startDate) {
                    console.log('Subscription has not started yet');
                } else if (now > endDate) {
                    console.log('Subscription has expired');
                } else if (subscription.status !== 'active') {
                    console.log('Subscription status is not active');
                }
            }
        } else {
            console.log('No all-day subscription found for user:', userId);
        }

        return null;
    } catch (error) {
        console.error('Error in checkAllDaySubscription:', error);
        console.error('Error stack:', error.stack);
        return null;
    }
}

async function checkUserSubscription(userId) {
    try {
        console.log('Checking subscription for user:', userId);
        
        const { data, error } = await supabase
            .from('subscriptions')
            .select('*')
            .eq('user_id', userId)
            .eq('is_current', true)
            .order('created_at', { ascending: false })
            .limit(1);

        console.log('Subscription query result:', JSON.stringify({ data, error }, null, 2));

        if (error) {
            console.error('Error fetching subscription:', error);
            console.error('Full error object:', JSON.stringify(error, null, 2));
            return null;
        }

        if (data && data.length > 0) {
            const subscription = data[0];
            console.log('Potential active subscription found:', JSON.stringify(subscription, null, 2));
            
            // Check if the subscription is still valid
            const now = new Date();
            const endDate = new Date(subscription.end_date);
            if (endDate > now && subscription.status === 'active') {
                console.log('Valid active subscription confirmed');
                return subscription;
            } else {
                console.log('Subscription found but not active or expired:', JSON.stringify(subscription, null, 2));
                return null;
            }
        }

        console.log('No active subscription found for user:', userId);
        return null;
    } catch (error) {
        console.error('Unexpected error in checkUserSubscription:', error);
        console.error('Error stack:', error.stack);
        return null;
    }
}

export function saveSession(session) {
    if (session && session.access_token) {
        document.cookie = `supabase-auth-token=${session.access_token}; path=/; secure; SameSite=Strict`;
    }
}

export async function signOut() {
    try {
        await supabase.auth.signOut();
        clearAuthState();
        clearSession();
        localStorage.removeItem('supabase.auth.token');
        document.cookie = `supabase-auth-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        console.log('User signed out');
    } catch (error) {
        console.error('Error during sign out:', error);
    }
}

export function clearAuthState() {
    cachedSession = null;
    cachedSubscription = null;
    lastChecked = 0;
}

export function clearSession() {
    document.cookie = `supabase-auth-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function showAlert(message) {
    const alertContainer = document.getElementById('alertContainer');
    const alertMessage = document.getElementById('alertMessage');
    const alertTitle = document.getElementById('alertTitle');
    
    if (alertContainer && alertMessage && alertTitle) {
        alertTitle.textContent = "Information";
        alertMessage.textContent = message;
        alertContainer.classList.remove('hidden');
    }
}

export function setupDismissAlertButton() {
    const dismissAlertButton = document.getElementById('dismissAlertButton');
    if (dismissAlertButton) {
        dismissAlertButton.addEventListener('click', () => {
            const alertContainer = document.getElementById('alertContainer');
            if (alertContainer) {
                alertContainer.classList.add('hidden');
            }
            localStorage.removeItem('alertMessage');
        });
    }
}

export async function handleCheckoutCancellation() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('canceled') === 'true') {
        await signOut();
        showAlert('Your subscription process was canceled. You can try again when you are ready.');
        return true;
    }
    return false;
}

export async function getUserData(userId) {
    try {
        const { data, error } = await supabase
            .from('users')
            .select('name, email, profile_picture_url')
            .eq('user_id', userId)
            .single();

        if (error) {
            console.error('Error fetching user data:', error);
            return null;
        }

        return data;
    } catch (error) {
        console.error('Error in getUserData:', error);
        return null;
    }
}

export { supabase };